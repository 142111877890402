/* eslint-disable @typescript-eslint/no-use-before-define */

import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import VideoOverlay from './components/VideoOverlay';
import { VideoTagTableContext} from '../../context/VideoTagTableContext';

//https://www.npmjs.com/package/react-grid-layout
const useStyles = makeStyles((theme) => ({
  playerContainer:{
   
    padding: theme.spacing(0)
  },
  playerWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  reactPlayer:{
    position:'relative',
  }
}));
export const VideoPlayer = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const playerContainerRef = useRef(null);
  const { tagTableState } = React.useContext(VideoTagTableContext);

  const {annotationSets, selectedAnnotationSet, annotationFilter, forceVisualUpdateHack} = tagTableState;
  const [videoDuration, setVideoDuration] = useState(30);
  const [markers, setMarkers] = useState([]);

  useEffect(()=>{
    // console.log('Updating the annotation fileter video', annotationSets);
    if(selectedAnnotationSet === undefined || selectedAnnotationSet < 0){
      setMarkers([]);
      return;
    }
    if(annotationSets[selectedAnnotationSet] ){
      let filtered = annotationSets[selectedAnnotationSet].annotations;
      // console.log('Updating the annotation to fileter', filtered);
      if(annotationFilter){
        filtered = filtered.filter(tag=>tag.label === annotationFilter);
      }
      const lmarkers = [];
      let id= 0;
      for(const annot of filtered){
        lmarkers.push( {id: id, time: annot.startTime, color: 'blue', title: annot.label});
        id++;        
      }
      setMarkers(lmarkers);
    }
                  
  },[annotationFilter, annotationSets, selectedAnnotationSet, forceVisualUpdateHack]);

  const handleDuration = (duration) => {
    // console.log('onDuration', duration);
    setVideoDuration(duration);
    // const lmarkers = [{id: 1, time: 0, color: 'blue', title: 'start'},
    //   {id: 3, time: duration/2, color: 'blue', title: 'middle'},
    //   {id: 2, time: duration, color: 'blue', title: 'end'}];
    // setMarkers(lmarkers);

  };
  return (
    <Container 
      className={classes.playerContainer}
      maxWidth="md"
    >
      <div
        className={classes.playerWrapper}
        ref={playerContainerRef}        
      >
        <ReactPlayer
          className={classes.reactPlayer}
          controls
          height="100%"
          playbackRate={props.playbackRate}
          onDuration={handleDuration}
          onPause = {props.onPause}
          onReady = {props.onReady}
          onPlay = {props.onPlay}
          onSeek = {props.onSeek}
          playing={props.playing}
          ref={ref}
          url = {props.url}
          width="100%"
        />
        <VideoOverlay
          duration={videoDuration}
          //markers={markers}
        />
      </div>
    </Container>
  );
});
VideoPlayer.propTypes = {
  // durationSec: PropTypes.number,
  markers: PropTypes.array,
  onChangeDispayFormat: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onSeek: PropTypes.func,
  playing: PropTypes.bool,
  url: PropTypes.string,
  // onPlaybackRateChange: PropTypes.func,
};
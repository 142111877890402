/* eslint-disable react/no-multi-comp */
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink, Redirect } from 'react-router-dom';


import dragula from 'react-dragula';
import 'react-dragula/dist/dragula.css';

import {
  Avatar as MuiAvatar,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  LinearProgress as MuiLinearProgress,
  Divider as MuiDivider,
  Grid,
  Switch,
  FormControlLabel,
  Typography as MuiTypography
} from '@material-ui/core';

// import {CircularStatic} from '../components/Progress';

import { spacing } from '@material-ui/system';


import { CloudQueue as MuiCloudUpload } from '@material-ui/icons';

import {  sendVideoForProcessing} from '../../common/apiSetUtilities';

import * as RouteNames from '../../common/routeNames';
import { 
  useEmmaVideosState, 
  // useEmmaVideosDispatch,
  // VideoListActionTypes
} from '../../context/EmmaVideosContext';

import { useUserState } from '../../context/UserContext';
const CloudUpload = styled(MuiCloudUpload)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink
    innerRef={ref}
    {...props}
  />
));

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
// const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  margin-bottom: 4px;
  cursor: grab;
`;
// margin-bottom: ${props => props.theme.spacing(4)}px;
// border: 1px solid ${props => props.theme.palette.grey[300]};
  // background: ${props => props.theme.body.background};
const TaskWrapperContent = styled(CardContent)`
  &:last-child {
    padding-bottom: 4px;
  }
`;
// padding-bottom: ${props => props.theme.spacing(4)}px;
const Typography = styled(MuiTypography)(spacing);

const Avatar = styled(MuiAvatar)`
  float: right;
  margin-left: 1px;
  height: 32px;
  width: 32px;
`;
// margin-left: ${props => props.theme.spacing(1)}px;

const CenteredContent = styled.div`
  text-align: center;
`;
class Lane extends React.Component {
  handleContainerLoaded = container => {
    if (container) {
      this.props.onContainerLoaded(container);
    }
  };

  render() {
    const { title, description, children } = this.props;

    return (

      <Card mb={6}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            mb={4}
            variant="body2"
          >
            {description}
          </Typography>
          <div ref={this.handleContainerLoaded}>{children}</div>
        </CardContent>
      </Card>     
    );
  }
}
// export async function sendVideoForProcessing(videoId, deriveddataTypeName) {
//   //'/videos/process/{videoId}'\
//   //using path.join doesnt work
//   const endpoint = `${scoreitApiEndpoint}/videos/process/${videoId}`;
//   //ugh
//   return await axios.post(endpoint, {deriveddataType: deriveddataTypeName });
// }
const Task = (props) => {
  //These are baseically constants and can probably me moved to somewhere else to keep this component small
  const startTaskUrl='';
  const taskInfo = {
    1: {
      title: 'Pose Estimation (Infant)',
      description:'Run pose inference on selected images. Output will be infant body joints in each video frame.',
      hasVisual:true,
      url:`${startTaskUrl}`,
      keys:['keypoints','keypoints_video']
    },
    5: {
      title: 'Pose Estimation (All subjects)',
      description:'Run pose inference on selected images. Output will be body joints for all subjects (adults and infants) in each video frame.',
      hasVisual:true,
      url:`${startTaskUrl}`,
      keys:['keypoints_openpose','keypoints_video_openpose']
    },
    2: {
      title:'Motor Development Risk',
      description:'Run infant motor risk inference on the videos. Output will be a bayesian risk factor based on comparison to a normative database',
      hasVisual:true,
      url:`${startTaskUrl}`,
      keys:['motor_risk']
    },
    3: {
      title:'Score Estimation',
      description:'Determine motor score from all selected videos.',
      hasVisual:false,
      url:`${startTaskUrl}`,
      keys:[]
    },
    4: {
      title:'Run all tasks',
      description:'Run all tasks to determine motor score from the selected videos. Save intermediate results.',
      hasVisual:false,
      url:`${startTaskUrl}`,
      keys:[]
    }
  };

  const ddTypesMongo = {
    activities:'5e77d2930e8d6b212482f8ec',
    activities_video:'5e8e4b6f1c9d4400002d152e',
    keypoints_video:'5e77d2a10e8d6b212482f8ed',
    keypoints:'5e77d50b0e8d6b212482f8ef',
    keypoints_openpose:'5e77d2aa0e8d6b212482f8ee',
    keypoints_video_openpose:'5e77d5180e8d6b212482f8f0',
    motor_risk: '626c55598642e005c5c77954'
  };

  let taskTypeName='';

  const user = useUserState();
  const [completion] = useState(0);
  const [currentStatus] = useState('unknown');
  const [taskKey] = useState(props.taskKey);
  const [videoIdsToProcess] = useState(props.processIds);
  const [generateVideo, setGenerateVideo] = useState(false);

  const setTaskTypeIdFromTaskKey = (inputtedTaskKey) => {
    const {keys} = taskInfo[inputtedTaskKey];
    let ddTypeId = '';
    if (keys.length !==0){
      if(!generateVideo) {
        ddTypeId = keys[0];
      }else if(keys.length === 2){
        ddTypeId = keys[1];
      }
    }
    taskTypeName = ddTypeId;
  };

  useEffect(() =>{
    setTaskTypeIdFromTaskKey(taskKey);
  }, [taskKey]);


  const handleTaskButtonOnClick = (event) => {

    if(process.env.REACT_APP_VIDEO_DATA_SOURCE == 'mock'){
      // what are these values supposed to be?
      //'IVUfnRg7Kz6CAxid7ea7',
      //'h98ln4NdrdOC0G8nN0b6', //real
      let fakeVideoIds = ['h98ln4NdrdOC0G8nN0b6','qXZ4I9ytwH5OYfiAil5W'];
      // '5e78081a0e8d6b212482f8f8',
      fakeVideoIds = ['5e7808820e8d6b212482f8f9'];
      fakeVideoIds.forEach((id)=>{
        setTaskTypeIdFromTaskKey(taskKey);  
        const res = sendVideoForProcessing(user, id, this.taskTypeName);
        console.log(res);
      });
    } else { 
      setTaskTypeIdFromTaskKey(taskKey);  
      videoIdsToProcess.forEach((id)=>{
        // It is totally ok to just fire and forget these. They eventually
        // go into sqs then go to the backend
        const res = sendVideoForProcessing(user, id, taskTypeName);
        console.log(res);
      });
    }
  };

  const {title,description,hasVisual} = taskInfo[taskKey];
  return (     
    <TaskWrapper>
      <TaskWrapperContent>              
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            md={8}
          >
            <Typography
              mb={3}
              variant="h6"
            >
              {title}
            </Typography>
            <Typography
              mb={3}
              variant="body2"
            >
              {description}
            </Typography>
            <FormControlLabel
              control = {
                hasVisual? (
                  <Switch
                    checked={generateVideo}
                    onChange={setGenerateVideo}
                    value="generateVideo"
                  />
                ) : (<div />)                
              }
              label = {hasVisual? ('Turn on video generation'): (<div />)}
            />
          </Grid>
          <Grid
            item
            md={4}
          >
            <CenteredContent>
              <Button
                color="primary"
                component="span"
                mr={2}
                onClick={handleTaskButtonOnClick}
                size="medium"
                variant="outlined"
              >
                <CloudUpload mr={2} /> Run
              </Button>
            </CenteredContent>
          </Grid>
        </Grid>
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

const VideoTasks = (props) => {
  // Global
  const {selectedVideoIds, allVideos} = useEmmaVideosState();
  const user = useUserState();
  // Local
  const [redirect, setRedirect] = useState(null);
  const containers = [];
  const activityTaskElement = useRef();
  const scoreTaskElement = useRef();
  const allTasksElement = useRef();

  useEffect(()=>{
    dragula(containers);
  }, []);
  const onContainerReady = container => {
    containers.push(container);
  };

  const handleBackButtonClick = (event) => {

    setRedirect(RouteNames.EMMA_VIDEOS);
  };

  if (redirect) {
    // return <Redirect to={{pathname:redirect, state:{backpage:RouteNames.DASHBOARD}}}  />;
    return <Redirect to={{pathname:redirect, state:{backpage:RouteNames.VIDEO_TASKS}}} />;
  }
  if (user.authLevel < 10){
    return (
      <Typography variant="h5">
        You don&apos;t have permission to view this page.
      </Typography>
    );
  }
  return (
    <React.Fragment>
      <Typography
        display="inline"
        gutterBottom
        variant="h2"
      >
          Tasks 
      </Typography>  
      <Divider my={6} />
      <Grid
        container
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Typography>
            {selectedVideoIds.length} Videos
          </Typography>
          <Lane
            description="Run pose inference, activity detection, etc., on selected videos"
            onContainerLoaded={onContainerReady}
            title="Tasks"
          >
            <Task
              processIds={selectedVideoIds}
              taskKey={1}
            />
            <Task
              processIds={selectedVideoIds}
              taskKey={5}
            />
            <Task
              processIds={selectedVideoIds}
              taskKey={2}
            />
            <Task
              processIds={selectedVideoIds}
              taskKey={3}
            />
            <Task
              processIds={selectedVideoIds}
              taskKey={4}
            />
            <Button
              color="secondary"
              fullWidth
              onClick={handleBackButtonClick}
              variant="contained"
            >
                Back
            </Button>
          </Lane>
        </Grid>         
      </Grid>
    </React.Fragment>
  );
};

export default VideoTasks;

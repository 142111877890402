import React from 'react';
import {
  CardMedia,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import VideoPlayer from 'react-video-markers';
export class VideoDetailsTabPanel extends React.Component {
  state = {
    isPlaying: false,
    volume: 0.7
  };

  handlePlay = () => {
    this.setState({isPlaying: true});
  };
  
  handlePause = () => {
    this.setState({isPlaying: false});
  };
  
  handleVolume = value => {
    this.setState({volume: value});
  };      

  

  render() {
    const { 
      // children, 
      value, index, videoData, timestamp, videoOverlayData,
      videoOverlayDataOP, videoOverlayDataActivity, motorRisk, markers } = this.props;
    const { isPlaying, volume } = this.state;
    if (value && value === 1){
      return (
        <div hidden={value !== index}>

          <DialogTitle id="max-width-dialog-title">
           Pose (infant)
          </DialogTitle>
      
          <DialogContent>
            <DialogContentText>
        Pose results overlaid.
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoOverlayData}
              title="Pose (infant)"
            />
          </DialogContent>
        </div>
      );
    }
    else if (value && value === 2) {
      // open pose overlay
      return (
        <div hidden={value !== index}>
          <DialogTitle id="max-width-dialog-title">
          Pose (all)
          </DialogTitle>     
          <DialogContent>
            <DialogContentText>
              Pose results overlaid for all persons.
              {motorRisk &&  <div>
                <b> Motor Risk: {motorRisk.z} Confidence: {motorRisk.p} </b>
              </div>
              }
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoOverlayDataOP}
              title="Pose (all persons)"
            />
          </DialogContent>
        </div>
      );
    }
    else if (value && value === 3){
      return (
        <div hidden={value !== index}>
         
          <DialogTitle id="max-width-dialog-title">
              Activities
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            Activities detected in the snippet.
            </DialogContentText>           
            <VideoPlayer
              isPlaying={isPlaying}
              markers={markers}
              onPause={this.handlePause}
              onPlay={this.handlePlay}
              onVolume={this.handleVolume}
              url={videoOverlayDataActivity}              
              volume={volume}
            />    
          </DialogContent>
        </div>
      );
    } else {
      return (      
        <div hidden={value !== index}>      
          <DialogTitle id="max-width-dialog-title">
             Raw video
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
          Video recording from <b>{timestamp}</b>.
            </DialogContentText>
            <CardMedia 
              component="video"
              controls
              height="256"
              image={videoData != null && videoData.url}
              title="Activities"
            />
          </DialogContent>
        </div>
      );
    }
  }
}
import React, { useRef, useState, useEffect } from 'react';

import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

import { spacing } from '@material-ui/system';
import {
  Checkbox,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Snackbar
} from '@material-ui/core';

import { Delete} from '@material-ui/icons';

import VideoTableHead from './VideoTableHead';
import VideoTableToolbar from './VideoTableToolbar';
import {VideoDetails} from '../../../dialogs/VideoDetails';
import  { VideoTagDetails } from '../../../dialogs/VideoTagDetails';
import {stableSort, getSorting} from '../../../common/tableUtilities';
import { VideoDeleteDialog } from '../../../dialogs/AssessmenteDeleteDialog/VideoDeleteDialog';
import { 
  useEmmaVideosState, 
  useEmmaVideosDispatch,
  VideoListActionTypes
} from '../../../context/EmmaVideosContext';

import * as RouteNames from '../../../common/routeNames';
import { useUserState } from '../../../context/UserContext';
import { deleteVideo } from '../../../common/apiDelUtilities';

// import { constants } from 'zlib';

const CardContent = styled(MuiCardContent)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
overflow-y: auto;
max-width: calc(100vw - 12px);
`;
// max-width: calc(100vw - ${props => props.theme.spacing(12)}px);

const VideoInfoTable = (props) => {
  // Global
  const {selectedVideoIds, allVideos, statusMessage} = useEmmaVideosState();
  const emmaVideosDispatch = useEmmaVideosDispatch();
  const user = useUserState();

  // Local
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('timestamp');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [detailsPageOpen] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('No videos found in that date range');

  const detailsPageElement = useRef();

  const [videoTagDetailsOpen, setVideoTagDetailsOpen] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(undefined);
  const [videoObjectToDelete, setVideoObjectToDelete] = useState(undefined);
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(()=>{
    console.log('Getting the message', statusMessage);
    setToasterMessage(statusMessage);
    setToasterOpen(true);
  },[statusMessage]);
  
  const handleVideoTagDetailsClose = (value) => {
    setVideoTagDetailsOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const orderByProp = property;
    let orderProp = 'desc';

    if (orderBy === property && order === 'desc') {
      orderProp = 'asc';
    }
    setOrder(orderProp);
    setOrderBy(orderByProp);
  };

  // Move this function to the main context
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      emmaVideosDispatch({type:VideoListActionTypes.SELECT_VIDEOS});
    }else{
      emmaVideosDispatch({type:VideoListActionTypes.UNSELECT_VIDEOS});
    }
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    //Not sure where the action should actually happen, it be here or in the reducer.
    const selected = selectedVideoIds;
    const selectedIndex = selected.indexOf(id);
    if(selectedIndex > -1){
      selected.splice(selectedIndex, 1);
      emmaVideosDispatch({type:VideoListActionTypes.UNSELECT_VIDEO, selectedVideoIds:selected});
    }else{
      selected.push(id);
      emmaVideosDispatch({type:VideoListActionTypes.SELECT_VIDEO, selectedVideoIds:selected});
    }    
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const handleVideoDetails = data => {
    detailsPageElement.current.setVideoData(data, user);
    console.log('Video data:', data);
    detailsPageElement.current.handleClickOpen();
  };

  const handleTagVideo = event => {
    setVideoTagDetailsOpen(true);
  };

  const handleAssessmentsClick = (parentEmail) => {
    const selected = selectedVideoIds;
    console.log('Parent email', parentEmail);
    console.log('Items selected for assessment creation:',selected);
    const route = {pathname:RouteNames.ASSESSMENTS, state:{backpage:RouteNames.EMMA_VIDEOS}};
    if(parentEmail){
      route['parentEmail'] = parentEmail;
    }
    setRedirect(route);
  };

  const handleEditVideos = event => {
    setRedirect(RouteNames.VIDEO_TASKS);
  };

  const handleVideoDelete = (video, event) =>{
    setOpenConfirm(true);
    const idToUse = video.videoId ? video.videoId: video.id;
    setVideoToDelete(idToUse);
    setVideoObjectToDelete(video);
    event.stopPropagation();
  };

  const handleVideosRefresh = () => {
    console.log('Dispatching RESET message');
    emmaVideosDispatch({type:VideoListActionTypes.RESET, statusMessage:'Refreshing video list'});
  };

  const handleConfirmationClose = (confirmationValue, videoToDelete) =>{
    setOpenConfirm(false);
    if(confirmationValue === true){
      console.log('Delete video id:', videoToDelete);
      console.log('Delete Video Object:', videoObjectToDelete);

      deleteVideo(user.firebaseUser, videoToDelete, videoObjectToDelete)
        .then( (result)=> {
          console.log('Result:', result);
          handleVideosRefresh();
        });
      // deleteAssessmentAndUpdate(asmtTableDispatch, user.firebaseUser,videoToDelete)
      //   .then((result)=>{
      //     if(!result){
      //       console.log('Delete failed');
      //       return;
      //     }
      //     adjustAvailableAsmts(AssessmentListActionTypes.UPDATE, tableSearchState, user.firebaseUser, asmtTableDispatch);
      //   });
    }   
  };


  const handleToasterClose = (event,reason) => {
    console.log('toaster');
    if (reason === 'clickaway') {

      setToasterOpen(false);
      return;
    }
    setToasterOpen(false);
  };

  const checkSelection = (id) => {
    // console.log('Selected vidoes for check', selectedVideoIds);//Sanity checker for the dispatcher results
    return selectedVideoIds.indexOf(id) !== -1;
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, allVideos? allVideos.length - page * rowsPerPage:0);

  // Is this ever used?
  if (redirect) {
    return <Redirect to={redirect} />; // Back to?
  }
  if (user.authLevel < 0){
    return (
      <Typography variant="h5">
        Hmmm...you may not yet have content here.
      </Typography>
    );
  }

  console.log('selectedVideoIds:', selectedVideoIds);
  const firstId = selectedVideoIds == null ? 0: selectedVideoIds[0];
  const selectedVideo = allVideos==null ? null: allVideos.filter(n=>n.videoId === firstId);

  return (
    <Card mb={6}>
      <CardContent pb={0}>
        <Typography
          gutterBottom
          variant="h6"
        >
          Video Gallery
        </Typography>
        {user.authLevel != 0 && <Typography
          gutterBottom
          variant="body2"
        >
            Video gallery displayed below. Start by filtering videos using <b>start date</b> and <b>end date</b> pickers below and clicking the <b>Show</b> button.
        </Typography>
        }
        {user.authLevel == 0 && <Typography gutterBottom variant='body2'>
          Your uploaded home videos are below. To request a new assessment, go to the Assessments page and upload 
          a video to go with the request.
        </Typography>
        }
        <VideoDetails ref={detailsPageElement} user={user}/>
        <VideoTagDetails videoData={selectedVideo && selectedVideo[0]} open={videoTagDetailsOpen} onClose={handleVideoTagDetailsClose}/>
        <Divider my={2} ></Divider>
      </CardContent>
      <Paper>
        <VideoTableToolbar
          daysBack = {props.daysBack}
          handleAssessment = {handleAssessmentsClick}
          handleEditVideos = {handleEditVideos}
          handleTagVideo = {handleTagVideo}
        />
        <TableWrapper>
          <Table aria-labelledby="tableTitle">
            <VideoTableHead
              numSelected={selectedVideoIds ? selectedVideoIds.length:0}
              onRequestSort={handleRequestSort} 
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              rowCount={allVideos ? allVideos.length:0}
            />
            <TableBody>
              {allVideos && 
                stableSort(allVideos, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const idToUse = n.videoId ? n.videoId: n.id;
                    const isSelected = checkSelection(idToUse);
                    return (
                      <TableRow
                        aria-checked={isSelected}
                        hover
                        key={idToUse}
                        onClick = {()=>handleVideoDetails(n)}
                        role="checkbox"
                        selected={isSelected}
                        tabIndex={-1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected} 
                            onClick={event => handleClick(event, idToUse)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <img
                            src={n.thumbnailurl}
                            width="128"
                          />
                        </TableCell>
                        <TableCell align="right">{new Date(n.timestamp).toLocaleString() }</TableCell>
                        <TableCell align="right">
                          {n.duration == -1 ? (<div>&lt;5m</div>):(<div>.</div>)}</TableCell>
                        <TableCell
                          align="center"
                          style={{width:'10%'}}
                        ><IconButton onClick={(event)=>handleVideoDelete(n, event)}><Delete/></IconButton></TableCell>
                        
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component="div"
          count={allVideos ? allVideos.length:0}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Paper>
      <Snackbar
        autoHideDuration={5000}
        message = {toasterMessage} 
        onClose={handleToasterClose}
        open={toasterOpen}
      />

      <VideoDeleteDialog
        videoToDelete={videoToDelete}
        onClose={handleConfirmationClose}
        open={openConfirm}
      />
    </Card>
  );
};

export default VideoInfoTable;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {useEffect, useReducer, useRef, useState} from 'react';

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Tooltip,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Paper,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Fade,
  IconButton,
  FormControlLabel,
  FormLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tabs,
  Tab,
  Menu,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import {
  RefreshIcon,
  VideocamTwoTone,
  Close,
  AttachmentOutlined,
  AddCircleOutlined,
  HighlightOffRounded,
  ArrowBack
} from '@material-ui/icons';

import { 
  createStyles,
  makeStyles,
  // Theme 
} from '@material-ui/core/styles';
import {DropzoneDialog} from 'material-ui-dropzone';

import PropTypes from 'prop-types';

import { stableSort, getSorting } from '../../common/tableUtilities';
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer';
import { VideoTagTableFull } from '../VideoTagDetails/components/VideoTagTableFull';
import VideoTableHead from '../../pages/emmavideos/components/VideoTableHead';
import {AssessmentRecordDialog} from '../AssessmentRecordDialog/AssessmentRecordDialog';

import styled from 'styled-components';

import { 
  useUserState, 
  refreshToken
} from '../../context/UserContext';
import { useUserListState } from '../../context/UserListContext';

import { 
  VideoTagTableContext, 
  VideoTagTableTypes,
  initialVideoTabTable,
  videoTagTableReducer,
  createEmpty
} from '../../context/VideoTagTableContext';

import {
  AssessmentInfoContext,
  indexOfLibraryName,
  AssessmentInfoActionTypes
} from '../../context/AssessmentInfoContext';
import {
  useAsmtTableDispatch,
  useAsmtTableState,
  AssessmentListActionTypes, 
  adjustAvailableAsmts,   
} from '../../context/AssessmentListContext';

import {
  getAnnotationSetsViaObservationId 
} from '../../common/apiGetUtilities';
import { 
  setAnnotationSet, 
  setVideoPartial,
  patchAssessment
} from '../../common/apiSetUtilities';
import { 
  uploadReportAttachToAssessment 
} from '../../common/utilities';

import { 
  GeneralControlContext,
  GeneralControlActionTypes
} from '../../context/GeneralControlContext';
import { 
  ReportDialog
} from '../../dialogs/ReportDialog';

import TabPanel from '../../common/TabPanel';
import { captureThumb, captureThumbSmall, captureVideoFrame, fetchUrl } from '../../components/VideoPlayer/Frame';
import { getPosture, ChoosePostureClass, ChoosePostureClassV3 } from '../../common/poseUtilities';
import Loader from 'react-loader-spinner';
import { LiveScoring } from '../../components/Scoring/LiveScoring';

import { useAppSettings } from '../../context/AppSettingsContext';
const TableWrapper = styled.div`
overflow-y: auto;
max-width: calc(100vw - 12px);
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    saving: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    videoList: {
      minWidth: 340, 
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    carddivider: {
      margin: `${theme.spacing.unit}px 0`
    },
    cardmedia: {
      paddingTop: '56.25%'
    },
    cardcontent: {
      textAlign: 'left',
      padding: theme.spacing.unit * 3
    },
    detailsTab: {
      maxWidth:'100%',
      minWidth:'0%',
      width:'auto',
      padding:'8px',
      flexGrow:1
    },
    detailsRoot:{
      flexGrow:1,
      width:'100%'
    },
    uploadTab:{
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto'
    },
    container:{
      display: 'flex'
    },
    midContainer:{
      flexGrow: 1,
      flexDirection:'column',
      display: 'flex'
    },
    // tagSection:{
    //   height: '400px',
    //   maxHeight: '400px',
    //   // height:'100%',
    //   minHeight:'400px',
    //   overflow:'auto'
    // }
  }),
);

export const AssessmentDetails = (props) => {
  const {
    asmtData,
    handleAssessmentEdit,
  } = props;

  const {appSettingsState, appSettingsDispatch} = useAppSettings();
  const {postureUrl, postureEnabled, frameGrabInterval} = appSettingsState;
 
  const POSTURE_DETECTION = postureEnabled; //these two consts should come from an app SETTING
  const FRAME_GRAB_INTERVAL = frameGrabInterval; //5 seconds

  const videoPlayer = useRef(null);

  const user = useUserState();
  const userList = useUserListState();
  const [tagTableState, tagTableDispatch] = useReducer(videoTagTableReducer, initialVideoTabTable);
  const {currentAnnotSetOwner} = tagTableState;

  const {assessmentInfoState, assessmentInfoDispatch} = React.useContext(AssessmentInfoContext);
  const {instrumentLibraries, activeInstrumentName} = assessmentInfoState;
  //TODO: Find a cleaner of connecting table events to this element. 

  const {generalControlState, generalControlDispatch} = React.useContext(GeneralControlContext);
  const {isAsmtDialogVisible} = generalControlState;
  const asmtTableDispatch = useAsmtTableDispatch();
  const asmtTableSearchState = useAsmtTableState();
  const [selectedInstrumentIndex, setSelectedInstrumentIndex] = useState(indexOfLibraryName(assessmentInfoState));
  useEffect(() =>{
    setSelectedInstrumentIndex(indexOfLibraryName(assessmentInfoState));
  }, [instrumentLibraries, activeInstrumentName]);

  //mutable state
  const [isSaving, setIsSaving] = useState(false);
  // using null, undefined and a date for the last saved, null is init, undefined for error value
  const [lastSaved, setLastSaved] = useState(null);
  const [savedText, setSavedText] = useState('Waiting for first save...');

  const [isPlaying, setIsPlaying] = useState(false);
  const [addingLabel, setAddingLabel] = useState(false);

  const [volume, setVolume] = useState(0.7);
  const [playbackRate, setPlaybackRate] = useState(1.0);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('timestamp');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [notes, setNotes] = useState(asmtData?.notes);
  const [userForTagIdHack, setUserForTagIdHack] = useState(undefined);

  const [detailsChecked, setDetailsVis] = useState(false);
  //legacy variable used in details
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  
  const [reportChecked, setReportVis] = useState(false);

  const [videosListChecked, setVideosListVis] = useState(true);

  const [sortedObservations, setSortedObservations] = useState([]);
  const [selectedObservation, setSelectedObservation] = useState([]);
  const [selectedObservationIndex, setSelectedObservationIndex] = useState(0);

  const [detailsTabValue, setDetailsTabValue] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openRecord, setOpenRecord] = React.useState(false);
  const addMenuOpen = Boolean(anchorEl);

  const [frameGrabberTimer, setFrameGrabberTimer] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [thumbnailBlob, setThumbnailBlob] = useState(null);
  const [frameForPose, setFrameForPose] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [frameForPoseUrl, setFrameForPoseUrl] = useState(null);
  const [videoElement, setVideoElement] = useState(null);
  const [currentPose, setCurrentPose] = useState(null);

  useEffect(()=>{console.log('Video element SET:', videoElement);}, [videoElement]);
  useEffect( ()=>{
    console.log('isPlaying:', isPlaying);
    if (isPlaying && POSTURE_DETECTION){
      handleGetFrame();
    }
    else if (!isPlaying && POSTURE_DETECTION){
      console.log('Clearing framegrabber timer');
      clearInterval(frameGrabberTimer);
      setFrameGrabberTimer(null);
    }
  }, [isPlaying]);

  useEffect( ()=>{

    if(thumbnailBlob){
      setThumbnailUrl(URL.createObjectURL(thumbnailBlob));
      // you want to revoke this in 5 secs?
      //URL.revokeObjectURL(thumbnailUrl);
    }
  }, [thumbnailBlob]);

  useEffect( async()=>{

    if (frameForPose){

      setFrameForPoseUrl(URL.createObjectURL(frameForPose));
      //revoke this in 5 secs
      const response = await getPosture(frameForPose/*send blob data*/);
      //console.log('posture response:', response);            
      const p = ChoosePostureClassV3(response);
      console.log('posture:', p);
      setCurrentPose(p);
    }
  }, [frameForPose]
  );

  useEffect( ()=> {
    //revoke URL in 5 secs
    setTimeout(()=>{
      console.log('Revoking thumbnailUrl');
      URL.revokeObjectURL(thumbnailUrl);},5000);
  }, [thumbnailUrl]);

  useEffect( async ()=> {

    //revoke URL in 5 secs
    setTimeout(()=>{
      console.log('Revoking frameForPoseUrl');
      URL.revokeObjectURL(frameForPoseUrl);},5000);
    
  }, [frameForPoseUrl]);

  const captureMediaStream = (videoEl) => {
    if (videoEl){
      try{

        videoEl.addEventListener('canplay', (event) => { //triggered when VideoPlayer determines it can play the selected media
          
          if(!mediaStream){
            let stream;
            const fps = 0;
            console.log('videoelement listener says PLAY:', event);
            console.log('capturing stream');
            if (videoEl.mozCaptureStream) {
              stream = videoEl.mozCaptureStream(fps);
              console.log('mozstream:', stream);
              setMediaStream(stream);
            } else if (videoEl.captureStream) {
              console.log('video element capturestream:', videoEl.captureStream);
              try{
                stream = videoEl.captureStream(fps);
                console.log('stream;', stream);
                setMediaStream(stream);
              }catch(err){console.log('capture stream error:', err);}
            } else {
              console.error('Stream capture is not supported');
              stream = null;
            }
          }
        });


      } catch (err) { console.log('error:', err);}
    }
  };

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddClose = () => {
    setAnchorEl(null);
  };

  const handleNewRecording = () => {
    setAnchorEl(null);
    // start new recording dialog
    setOpenRecord(true);
  };

  const handleRecordClose = () => {
    adjustAvailableAsmts(AssessmentListActionTypes.UPDATE, asmtTableSearchState, user.firebaseUser, asmtTableDispatch);
    setOpenRecord(false);
  };
  // initialize the table
  useEffect(()=>{
    const tempSortedObservations = stableSort(asmtData.observations, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setSortedObservations(tempSortedObservations);
  },[]);
  
  // lack of design/planning is forcing this hack to enable to recalc of the score 
  // for a user change....gads so bad, this whole file needs to be torn apart...
  useEffect(()=>{
    const userForEmail = userList.allUsers.find((user)=>user.localEmail === currentAnnotSetOwner);
    if(userForEmail){
      setUserForTagIdHack(userForEmail.emmauserId);
    }

    // a forced save event if the request tag set matches the current owner
    // TODO: why was this considered a hack
    if(tagTableState.forceSaveHack && currentAnnotSetOwner === user.localEmail){
      handleSave();
    }
  },[currentAnnotSetOwner]);

  useEffect(() => {
    console.log('We have an observation changed');
  }, [asmtData.observations]);

  useEffect(() => {
    setNotes(asmtData.notes);
  }, [asmtData.notes]);

  useEffect(()=>{
    if(isSaving){
      setSavedText('Saving');
      return;
    } 
    let nowTime = (new Date(Date.now())).toLocaleTimeString('en-US');

    let outString = `Save Failed: ${nowTime}`;
    if(lastSaved === null){
      setSavedText('Waiting for next save...');
      return;
    }
    if(lastSaved !== undefined){
      nowTime = lastSaved.toLocaleTimeString('en-US');
      outString = `Last saved: ${nowTime}`;
    }
    setSavedText(outString);

  }, [isSaving]);
  //constants
  const classes = useStyles();

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, asmtData.observations.length - page * rowsPerPage);
  
  useEffect( ()=> {
    if (isPlaying){
      if (!selectedObservation.hasBeenViewed) {
        selectedObservation.hasBeenViewed = true;
        setVideoPartial(user.firebaseUser, selectedObservation.videoId, {hasBeenViewed: true});
      }
    }
  }, [isPlaying, addingLabel]);

  //this is where you obtain references to the stream VideoPlayer is playing
  //which is then used to grab frames every X secs
  //for baby pose classification
  const handleReady =   () => {
    if (videoElement == null && POSTURE_DETECTION){
      console.log('Player ONREADY');
      const vl = document.querySelector('video');
      if (vl){
        vl.setAttributeNS(null,'crossOrigin', 'anonymous');//this is necessary as CORS prevents you grabbing a stream
        console.log('videoel with crossorigin attr:', vl);
        fetchUrl(vl.src).then( (fetchedUrl) => {

          console.log('returned blob url from fetch:', fetchedUrl);
          if (fetchedUrl){
            console.log('setting videoel new url:', fetchedUrl);
            vl.src = fetchedUrl;
            if (vl !== null){
              console.log('SETTING videoelement');
              setVideoElement(vl);
            } 
            console.log('videoEl with new url:', vl);
            captureMediaStream(vl);
          }
        });
      
      }
    }
  };

  const handlePlay = () => {
    if (addingLabel){
      setAddingLabel(false);
    }
    if (!isPlaying){
      setIsPlaying(true);
    }
  };
  
  const handlePause = () => {
    if (isPlaying){
      setIsPlaying(false);
    }
  };
  
  const handleVolume = (value) => {
    setVolume(value);
  };

  const handleClose = () => {
    handleAssessmentDetailsClose();
  };

  const handleGetFrame = () => {
    const timer = setInterval(getFrameFromPlayer, FRAME_GRAB_INTERVAL);
    console.log('Frame grab interval:', FRAME_GRAB_INTERVAL);
    setFrameGrabberTimer(timer); //every 5 secs

  };

  const getFrameFromPlayer =   () => {
    const currentTime = returnCurrentTimeStamp();
    console.log('current time:', currentTime);
    console.log('videoel:', videoElement);
    if(videoElement){
      console.log('calling captureThumbSmall');
      captureThumbSmall(videoElement).then( (thumbnail)=>{
        console.log('thumbnail:', thumbnail);
        if(thumbnail !== null){
          console.log('setting new thumbnail blob');
          setThumbnailBlob(thumbnail);
        }
      });

      console.log('calling frameForPose');
      captureThumb(videoElement).then( (thumbnail)=>{
        if(thumbnail !== null){
          console.log('setting new frameForPose blob');
          setFrameForPose(thumbnail);
        }
      });
    }
  };

  const handleAssessmentDetailsSave = (value) => {
    console.log('The value', value);
    patchAssessment(user.firebaseUser, value); // the parent may need to call adjustAvailableAsmts
  };
  const handleAssessmentDetailsClose = (value) => {
    generalControlDispatch({type:GeneralControlActionTypes.ASMTINFO_DIALOG, 
      isAsmtDialogVisible:false}); 

    //kind of lame but oh well. -jo
    // agreed on the lame, but needed since all actions based off the table depend
    // on the info stored in the table instead of getting it fresh
    adjustAvailableAsmts(AssessmentListActionTypes.UPDATE, asmtTableSearchState, user.firebaseUser, asmtTableDispatch);
  };
  // This is the actual save call, all triggers lead to this point
  const doSave = async () => {
    if(tagTableState.currentAnnotSetOwner !== user.localEmail){
      setLastSaved(undefined);
      console.log('doSave: Cant save something that is not yours, this message should never appear');
      setIsSaving(false);
      return;
    }
    // TODO: remove/mitigate the need to always refresh the user token 
    // (would probably involve a timer class that refreshes the token when it nears its set expiration time)
    await refreshToken(user);
    setIsSaving(true);
    handleAssessmentDetailsSave({assessmentId:asmtData.assessmentId, notes:notes});
    //save tags
    {
      // When the currentAnnotSetOwner is not the current user things goet odd.
      // Next stage is to not allow mismatched saves, but for now we will
      // change the user to the current user and copy the values to the new user.
      // this way of doing things is super confusing and should be changed asap
      const currentIndex = indexOfLibraryName(assessmentInfoState);
      const annotSetMap = tagTableState.annotationSetMap.get(tagTableState.currentAnnotSetOwner);
      const set = annotSetMap && annotSetMap.find(annotSet=>annotSet.instrumentIndex ===currentIndex);

      // setToPost should be of type AnnootationSetSchemaProp
      const setToPost = {};
      setToPost.emmaUserId = user.emmauserId; //save as our own, even if it was somebody else's
      setToPost.videoId = selectedObservation.videoId;
      setToPost.instrumentIndex = selectedInstrumentIndex;
      setToPost.observationId = selectedObservation.observationId;

      // Send the stuff to the database
      // not sure if we care about the video anymore, but i suppose it is a solid
      // backup form 
      if(selectedObservation.videoId !== '') {
        if(set){
          setToPost.annotations = set.annotations;
        }
        try{
          await setAnnotationSet(user, setToPost);
          setVideoPartial(user.firebaseUser, selectedObservation.videoId, {tagCount: setToPost.annotations?.length});
          setLastSaved(new Date(Date.now()));
        } catch(err){
          let message = '';
          if (err.response) {
            // client received an error response (5xx, 4xx)
            console.log('doSave Found the response error', err.response);
            message = err.response.data.error.message;
          } else if (err.request) {
            // client never received a response, or request never left
            console.log('doSave Found the request error', err.request);
            message = 'Enable to connect to API';
          } else {
            // anything else
            message = 'Unknown error';
            console.log('A different error from doSave',message);
          }
          // It would be better to have a big message box appear for this error, but for now
          // display it in log and let the user know save failed
          setLastSaved(undefined);
        }  
      }
    }

    setIsSaving(false);
    assessmentInfoDispatch({type:AssessmentInfoActionTypes.HACK_UPDATESCORE});
  };

  const idleTime = 2000;
  const saveTimer = useRef(-1);
  const handleSave = () => {
    if (isSaving) {
      clearTimeout(saveTimer.current);
    } else {
      setIsSaving(true);
    }
    const id = setTimeout(doSave, idleTime);
    saveTimer.current = id;
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }    
    handleSave();
  }, [notes]);

  //ick copy paste
  const handleRequestSort = (event, property) => {
    const orderByProp = property;
    let orderProp = 'desc';

    if (orderBy === property && order === 'desc') {
      orderProp = 'asc';
    }
    setOrder(orderProp);
    setOrderBy(orderByProp);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const updateVideoTagTableState = async (observation) => {
    await refreshToken(user.firebaseUser);
    setSelectedObservation(observation);
    const annotSetsFromApi = (await getAnnotationSetsViaObservationId(user.firebaseUser, observation.observationId));
    // The type that gets returned is AnnotationSetFromApi[]
    // which is AnnotationSetSchemaProps with a EmmaUserSchemaProps
    const userIdentifier = user.localEmail;
    const annotSetMap = new Map();
    annotSetMap.set(userIdentifier,undefined);
    const initialTagTableContextValues = {
      observationId:observation.observationId, 
      currentAnnotSetOwner:user.localEmail,
      annotationFilter:undefined,
      annotationSetMap: annotSetMap,
      forceVisualUpdateHack: false
    };
    if(annotSetsFromApi.length !== 0){

      // Convert the annotationsetschema to tagtablecontext
      for (const annotSet of annotSetsFromApi) {
        // the annot set comes with user relation call
        const {emmaUser, ...annotSchemaProp} = annotSet;
        // console.log('annot-getting', emmaUser);
        // ug dang lack of design. Remember that this user comes directly from
        // the database so use email instead of localEmail
        if(emmaUser){
          const email = emmaUser.email;
          if(annotSetMap.has(email) && annotSetMap.get(email)){
            annotSetMap.get(email).push(annotSchemaProp);
          }else{
            annotSetMap.set(email, [annotSchemaProp]);
          }
        }else{
          const userId = annotSet.emmaUserId;
          // in the odd case that a user was deleted and re-added
          // the emmauser id will not match to anything so no user/name
          if(annotSetMap.has(userId) && annotSetMap.get(userId)){
            annotSetMap.get(userId).push(annotSchemaProp);
          }else{
            annotSetMap.set(userId, [annotSchemaProp]);
          }
        }
      }
      initialTagTableContextValues.annotationSetMap = annotSetMap;

      tagTableDispatch({type: VideoTagTableTypes.LOAD, ...initialTagTableContextValues});
    }else{
      tagTableDispatch({type: VideoTagTableTypes.LOAD, ...initialTagTableContextValues});
    }
  };

  const handleSelectObservation = (observation) => {
    if (isPlaying){
      setIsPlaying(false);
    }
    // why set this to false?
    // bharath april 23 2021
    setSelectedObservationIndex(sortedObservations.indexOf(observation));
  };

  useEffect(() => {
    // console.log('Observations', sortedObservations,selectedObservationIndex);
    if(sortedObservations.length > 0){
      //
      // if first page has 10 observations, for instance, and you paginate to page #2, which only has one observation,
      // you get an error because selectedObservationIndex might stil be something like 9
      // bharath april 20 2021
      //
      if (selectedObservationIndex >= sortedObservations.length){
        setSelectedObservationIndex(0);
        updateVideoTagTableState(sortedObservations[0]);
      }
      else{
        updateVideoTagTableState(sortedObservations[selectedObservationIndex]);
      }
    }
  }, [selectedObservationIndex, sortedObservations]);
  useEffect(()=>{
    const tempSortedObservations = stableSort(asmtData.observations, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setSortedObservations(tempSortedObservations);
  },[order, orderBy, page,rowsPerPage]);

  const handleAddLabel = () => {
    if (!addingLabel){
      setAddingLabel(true);
    }
    if (isPlaying){
      setIsPlaying(false);
    }
    const currentTimeStamp =  returnCurrentTimeStamp();
    return currentTimeStamp;
  };

  const returnCurrentTimeStamp = () => {
    const currentTimeStamp = (videoPlayer.current ? videoPlayer.current.getCurrentTime() : null );
    return currentTimeStamp;
  };

  const handleSaveLabels = () => {
    handleSave();
  };

  // WHAT?!?! this does nothing?!?!
  const onSeek = () => {
    // console.log('AssessmentDetails.onSeek entered');
    // const currentTimeStamp = (videoPlayer.current ? videoPlayer.current.pauseVideo() : null );
    // console.log('Current time after seek:', currentTimeStamp);
    // console.log('Current time after seek a:', videoPlayer.current.getCurrentTime());
  };

  const seekTo = (amt) => {
    // console.log('SEEKTO amt:', amt);
    //setIsPlaying(false);
    // wouldn't we want the play or pause state of the player to be maintained while we SEEK?
    videoPlayer.current.seekTo(amt);
  };
  const jumpTime = (timeMs)=>{
    const currentTime = videoPlayer.current.getCurrentTime();
    // for now no pausing on the jump?
    // maybe pause though...
    const nextTime = currentTime+timeMs;
    videoPlayer.current.seekTo(nextTime);
  };
  
  const handleDetailsVisChange = () => {
    setDetailsVis( (prev) => !prev);
  };
  const handleReportVisChange = () =>{
    setReportVis((prev) => !prev);
  };

  const handleVideosListVisChange = () => {
    setVideosListVis( (prev) => !prev);
  };

  const handlePlaybackRateChange = (event) => {
    console.log('Set playback rate ', event.target.value);
    setPlaybackRate(event.target.value);
  };

  const onDialogKeyDown = (e) => {
    if(detailsChecked || reportDialogOpen || reportChecked) return;
    //yuck, but override spacebar behavior unless we're focused on a INPUT/TEXTAREA tag
    // overriding spacebar failed. video was holding it in focus at random times so sometimes
    // the vidoe would not pause (was browser dependent)
    if (e.key === 'd' && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
      //add label
      // re-adding the disabling of the spacebar pressing since it was inconsistant and 
      //caused user confusion.
      const currentTimeStamp = handleAddLabel();
      const toAdd = createEmpty(currentTimeStamp, indexOfLibraryName(assessmentInfoState));
      tagTableDispatch({type:VideoTagTableTypes.ADDITEM, ...toAdd});
      e.preventDefault();
    } else if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
      //select previous/next video in list

      setSelectedObservationIndex(Math.min(rowsPerPage - 1, Math.max(0, 
        e.key === 'ArrowUp' ? selectedObservationIndex - 1 : selectedObservationIndex + 1)));
      e.preventDefault();
    } else if (e.key === 'Tab') {
      //start/stop
      setIsPlaying(!isPlaying);
      if (addingLabel){
        setAddingLabel(false);
      }
      e.preventDefault();
    }else if (e.key==='f' && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA'){
      jumpTime(15);
    }else if(e.key === 's' && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA'){
      jumpTime(-5);
    }else if(e.key === 'q' && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA'){
      setPlaybackRate(.5);
    }else if(e.key === 'a' && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA'){
      setPlaybackRate(1);
    }
  };

  const setReport = (files) => {
    if (files.length === 1) {
      const file = files[0];
      uploadReportAttachToAssessment(user, file, asmtData);
    }
  };

  const handleDetailsTabChange = (event, newValue) => {
    setDetailsTabValue(newValue);
  };

  const a11yprops = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };
  return (  
    <Paper mt={4}>
      <React.Fragment>               
        <Dialog
          aria-labelledby="max-width-dialog-title"
          fullScreen
          onClose={handleClose}
          onKeyDown={onDialogKeyDown}          
          open={isAsmtDialogVisible}
        >
          <AppBar
            className={classes.appBar}
          >
            <Toolbar>
              <Tooltip title="Back to Assessment List">
                <IconButton
                  aria-label="close"
                  color="inherit"
                  edge="start"
                  onClick={handleClose}
                  variant="outlined"
                >
                  <ArrowBack                
                    fontSize="large"
                  />
                </IconButton>
              </Tooltip>
              <Typography
                className={classes.title}
                style={{marginRight:20}}
                variant="body"
              >
                Parent: {asmtData.parent}
              </Typography>
              <Typography
                className={classes.title}
                style={{marginRight:20}}
                variant="body"
              >
                { new Date(asmtData.date).toDateString()}
              </Typography>

              {thumbnailBlob!==null ? (
                <img src={thumbnailUrl}/>):
                (<div />)
              }
              <IconButton
                aria-controls="fade-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleAddClick}
              >
                <AddCircleOutlined/>
              </IconButton>
              <Menu
                TransitionComponent={Fade}
                anchorEl={anchorEl}
                id="fade-menu"
                keepMounted
                onClose={handleAddClose}
                open={addMenuOpen}
              >
                <MenuItem
                  // disabled
                  onClick={()=>{
                    setAnchorEl(null);
                    handleAssessmentEdit(asmtData, undefined);
                  }}
                >
                  <ListItemIcon>
                    <AttachmentOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Add Video File" />
                </MenuItem>
                <MenuItem onClick={handleNewRecording}>
                  <ListItemIcon>
                    <VideocamTwoTone fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="New Recording" />
                </MenuItem>
              </Menu>
              <FormControlLabel
                control={
                  <Switch
                    checked={videosListChecked}
                    onChange={handleVideosListVisChange}
                  />
                }
                label="Videos"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={detailsChecked}
                    onChange={handleDetailsVisChange}
                  />
                }
                label="Details"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={reportChecked}
                    onChange={handleReportVisChange}
                  />
                }
                label="Report"
              />
              {isSaving && <CircularProgress color="secondary" />}
              <Typography
                className={classes.saving}
                color={lastSaved === undefined?'error':'inherit'}
                variant="h6"
              >
                {savedText}
              </Typography>
              
            </Toolbar>
          </AppBar>
          <Divider my={6} />
         
          <Grid
            alignItems="stretch" 
            container
            direction="row"
            justify="center"
            spacing={2}
          >
            <Grid 
              container
              direction="column"
              item
              lg={7}
              md={7}
              sm={7}
              spacing={2}
              xl={7}  
              xs={6}
            >
              {tagTableState && //Why are there two calls to the provider?
            <VideoTagTableContext.Provider value={{tagTableState, tagTableDispatch}}>
              <div className={classes.midContainer}>
                {/* Video Player */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Paper elevation={3}>

                    {(videoElement === null && POSTURE_DETECTION) &&
                    <div>
                      <Grid direction="row" container>
                        <Grid item>
                          <Typography variant="button" style={{color:'blue'}}>Processing...</Typography>
                        </Grid>
                        <Grid item>
                          <Loader type="Grid" color='#00bfff' width={64} height={16}/>
                        </Grid> 
                      </Grid>
                    </div>
                    }
                    
                    <div>
                      <VideoPlayer
                        disabled = {videoElement === null}
                        // controls
                        id="videoPlayer"
                        onPause={handlePause}
                        onPlay={handlePlay}
                        onReady={handleReady}
                        onSeek = {onSeek}
                        onVolume={handleVolume}
                        playbackRate={playbackRate}
                        playing={isPlaying && !addingLabel}
                        ref = {videoPlayer}
                        url={selectedObservation && selectedObservation.url}
                        volume={volume}
                      />
                    </div>
                  </Paper>
                  {(currentPose !== null && POSTURE_DETECTION) ? 
                    (<Typography variant="button" style={{color:'blue'}}>Current pose: {currentPose}</Typography>) :
                    (<div />)}
                </Grid>
                {/* END Video Player */}
              
                {/* Video list for the assessment */}
                {videosListChecked && sortedObservations.length > 1 &&
                
                <Grid
                  item   
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Paper elevation={3}>
                    <TableWrapper>
                      <Table
                        aria-labelledby="tableTitle"
                        className={classes.videoList}
                      >
                        <VideoTableHead
                          onRequestSort={handleRequestSort} 
                          order={order}
                          orderBy={orderBy}
                          rowCount={-1}
                        />
                        <TableBody>
                          {sortedObservations
                            .map(observation => {
                              const idToUse = observation.videoId ? observation.videoId: observation.id;
                              return (
                                <TableRow
                                  hover
                                  key={idToUse}
                                  onClick = {()=>handleSelectObservation(observation)}
                                  role="checkbox"
                                  selected={selectedObservation && (observation.videoId === selectedObservation.videoId)}
                                  tabIndex={-1}
                                >
                                  <TableCell align="right">
                                    <img
                                      src={observation.thumbnailurl}
                                      width="128"
                                    />
                                  </TableCell>
                                  <TableCell align="right">{new Date(observation.timestamp).toLocaleString() }</TableCell>
                                  <TableCell align="right">{observation.duration}</TableCell>
                                  <TableCell align="right">{observation.tagCount}</TableCell>
                                  <TableCell align="right">{observation.hasBeenViewed.toString()}</TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableWrapper>
                    <TablePagination
                      backIconButtonProps={{
                        'aria-label': 'Previous Page'
                      }}
                      component="div"
                      count={asmtData.observations.length}
                      nextIconButtonProps={{
                        'aria-label': 'Next Page'
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </Paper>
                </Grid>}
                
              </div>
              {/* END Video list for the assessment */}

            </VideoTagTableContext.Provider>}
            </Grid>
            <Grid
              container
              direction="column"
              item
              lg={5}
              md={5}
              sm={5}
              spacing={1}
              xl={5}
              xs={6}
            >
              {/* Motor Tags List */}
              {tagTableState && //Why are there two calls to the provider? maybe so they can be split up later?
              <VideoTagTableContext.Provider value={{tagTableState, tagTableDispatch}}>

                <Grid 
                  item
                  xs
                > 
                  <div>   
                    <FormControl component="fieldset">
                      <FormLabel labelPlacement="start">Playback Speed:</FormLabel>
                      <RadioGroup
                        aria-label="position"
                        name="position"
                        onChange={handlePlaybackRateChange}
                        row
                        value={playbackRate}
                      >
                        <FormControlLabel
                          checked={playbackRate == 0.25}
                          control={<Radio color="primary" />}
                          label="0.25x"
                          labelPlacement="start"
                          size="small"
                          value="0.25"
                        />
                        <FormControlLabel
                          checked={playbackRate == 1.0}
                          control={<Radio color="primary" />}
                          label="1x"
                          labelPlacement="start"
                          size="small"
                          value="1.0"
                        />
                        <FormControlLabel
                          checked={playbackRate == 2.0}
                          control={<Radio color="primary" />}
                          label="2x"
                          labelPlacement="start"
                          size="small"
                          value="2.0"
                        />
                        <FormControlLabel 
                          checked={playbackRate == 5.0} 
                          control={<Radio color="primary" />} 
                          label="5x"
                          labelPlacement="start"
                          size="small" 
                          value="5.0"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <Paper elevation={3}>
                    <VideoTagTableFull
                      handleAddLabel = {handleAddLabel}
                      handleSaveLabels = {handleSaveLabels}
                      handleSeekTo = {seekTo}
                      // instrument = {instruments[selectedInstrumentIndex]}
                      returnCurrentTimeStamp = {returnCurrentTimeStamp}
                      videoId = {selectedObservation && selectedObservation.videoId}
                    />
                  </Paper>
                </Grid>
                {detailsChecked &&
                <Grid 
                  item
                  xs
                >
                  <Paper elevation={3}>
                    <LiveScoring/>
                  </Paper>
                </Grid>
                }
              </VideoTagTableContext.Provider>} 
              {/* END Motor Tags List */}
            </Grid>
          </Grid>
        </Dialog>

        <AssessmentRecordDialog 
          assessmentProp={asmtData}
          onClose={handleRecordClose}
          open={openRecord}
        />

        <ReportDialog
          asmtInformation={asmtData}
          // availableInstruments= {instruments}
          dialogComplete = {handleReportVisChange}
          isOpen={reportChecked}
          // openPreview={()=>setReportPreviewVis(true)}
          reviewForId={userForTagIdHack}
          // selectedInstrumentIndex={selectedInstrumentIndex}
        />
      </React.Fragment>
    </Paper>
  );
};
//https://reactjs.org/docs/typechecking-with-proptypes.html
AssessmentDetails.propTypes = {

  asmtData: PropTypes.object.isRequired,
  // instruments: PropTypes.array.isRequired,
  handleAssessmentEdit: PropTypes.func.isRequired, // This is basically dummy function used to hack update the table
  // onClose: PropTypes.func.isRequired,
  // onRefresh:PropTypes.bool.isRequired,
  // onSave: PropTypes.func.isRequired,
  // open: PropTypes.bool.isRequired,
};
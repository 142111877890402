import axios from 'axios';

import { 
  UserProps, 
  FirebaseUserProps
} from './entityUtilities';

import { 
  VideoSchemaProps,
  ObservationSchemaProps,
  EmmaUserSchemaProps,
  AssessmentSchemaProps,
  ReportSchemaProps,
  emptyUserMongoSchema,
  AnnotationSetSchemaProps
} from './mongoSchemas';
import { getScoreitApiEndpoint, setAxiosDefaultHeaders } from './apiBaseUtilities';
import { de } from 'date-fns/locale';
const scoreitApiEndpoint = getScoreitApiEndpoint();

// The setting functions

// Passing in the subject id in the payload will ensure that a new
// subject is not created each time ( i think, tests?)
// also note that we seem to do a full change each time.
export async function setSubjetInfo(firebaseUser:FirebaseUserProps|undefined, subjectPayload:any){

  console.log('subj pay', subjectPayload);
  if(!scoreitApiEndpoint || !firebaseUser) return {};
  const url = `${scoreitApiEndpoint}/subjects`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.post(url, subjectPayload);
    console.log('the sbu response', response);
    if(response.data){

      return response.data;
    }
  }catch(error){
    console.log('API ERROR:',error);
    return {};
  }
  // an interesting an previously unnoticed feature?
  // a patch/update call on the api does not return any values
  // meanwhile a create does. But the UI is designed to always
  // expect something back (at least it seems that way)
  // so if there was no error return exactly what was put in
  // and hope for the best.
  return subjectPayload;
}

export async function setObservationInfo(firebaseUser:FirebaseUserProps|undefined, 
  observationPayload:ObservationSchemaProps)
  :Promise<ObservationSchemaProps|undefined>{
  if(!scoreitApiEndpoint || !firebaseUser) {
    return undefined;
  }
  const url = `${scoreitApiEndpoint}/observations`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.post(url, observationPayload);
    return response.data;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return undefined;
}

export async function patchObservationInfo(firebaseUser:FirebaseUserProps|undefined, observationPayload:Partial<ObservationSchemaProps>){
  if(!scoreitApiEndpoint || !firebaseUser) return undefined;
  const obsId = observationPayload.observationId;
  if(!obsId) return undefined;
  delete observationPayload.observationId;
  const url = `${scoreitApiEndpoint}/observations/${obsId}`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.patch(url, observationPayload);
    return response.data;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return undefined;
}

export async function addNotification(currentFirebaseUser: FirebaseUserProps|undefined,
  userIdToUpdate: string | undefined,
  title: string|undefined, text: string|undefined){


  if(!scoreitApiEndpoint) return;
  if(!currentFirebaseUser) return;
  const {uid, bearerToken } = currentFirebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  const payload = { 'emmaUserId':userIdToUpdate, 'title': title, 'text': text, url:'/' };
  console.log('Add Notification with payload:', JSON.stringify(payload));
  const url = `${scoreitApiEndpoint}/notifications`;
  try{
    await axios.post(url, payload);
  }catch(error){
    console.log('Add Notification API ERROR:',error);
  }  

}

export async function addDeviceToken(currentFirebaseUser:FirebaseUserProps|undefined, 
  userIdToUpdate:string|undefined,
  propUpdates:Partial<EmmaUserSchemaProps>,
  tokenValue: string):Promise<Partial<EmmaUserSchemaProps>>{
  if(!scoreitApiEndpoint || !currentFirebaseUser) return {};


  const mongoUser:Partial<EmmaUserSchemaProps> = {...propUpdates};

  // check to see if tokenValue is present in the deviceIds array
  let devices = propUpdates.deviceIds;

  let devicePresent = false;
  if (!devices || devices.length == 0) {
    devicePresent = false;
  }
  else {
    devicePresent = (devices.indexOf(tokenValue) > -1);
  }

  if (devicePresent) {
    console.log('Device id already present');
    return mongoUser; // the device is already present. no need to add 
  }

  if (!devices){
    devices = [];
  }
  devices?.push(tokenValue);
  propUpdates.deviceIds = devices;
  console.log('Propupdates before adding to API:', propUpdates);


  setUserInfo(currentFirebaseUser, userIdToUpdate, propUpdates)
    .then(updatedUser=>{
      // The updated user will have the emmauserid/mongoid
      console.log('The user after token update:', updatedUser);
    });
  

  return mongoUser;

}
// The payload needs to be an object create with '' in the key parameter
// not sure why and that also might change dlp 
// tests? pretty please?
export async function setUserInfo(currentFirebaseUser:FirebaseUserProps|undefined, 
  userIdToUpdate:string|undefined,
  propUpdates:Partial<EmmaUserSchemaProps>):Promise<Partial<EmmaUserSchemaProps>>{
  if(!scoreitApiEndpoint || !currentFirebaseUser) return {};
  console.log('Saving id', userIdToUpdate);
  console.log('data?', propUpdates);
  // For some reasoning trying to save the payload doesn't
  // work, gives a 422 or 415 error
  // Maybe fix the need for string properties another day (dlp)

  let mongoUser:Partial<EmmaUserSchemaProps> = {...propUpdates};
  if(userIdToUpdate === undefined){
    mongoUser = {...emptyUserMongoSchema, ...propUpdates};
  }

  const {uid, bearerToken } = currentFirebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  // Doing a post of an entity with a mongodb id will do the update
  // if not a new one is created.

  try{
    if(userIdToUpdate === undefined){
      const url = `${scoreitApiEndpoint}/emma-users`;
      // make sure the mongoUser has all the required properties or
      // the api will return a 422 error.
      console.log('POST to create new emmauser:', mongoUser);
      const result = await axios.post(url, mongoUser);
      if(result.data){
        return result.data;
      }    
    }else{
      const url = `${scoreitApiEndpoint}/emma-users/${userIdToUpdate}`;//userToUpdate.emmauserId
      console.log('PATCH to update emmauser:',mongoUser);
      const result = await axios.patch(url, mongoUser);
      if(result.data){
        return result.data;
      }   
    }

    return mongoUser;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return mongoUser;  
}

export async function setOTPlan(user:UserProps, planPayload:any){
  if(!scoreitApiEndpoint) return;
  if(!user.firebaseUser) return;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  const url = `${scoreitApiEndpoint}/plancreate`;
  try{
    await axios.post(url, planPayload);
  }catch(error){
    console.log('API ERROR:',error);
  }  
}

export function setSubscriptionInfo(rawData:string, uid:string, bearerToken:string){
  if(!scoreitApiEndpoint) return ;
  const url = `${scoreitApiEndpoint}/subscription`;
  // var raw = JSON.stringify(userSubscriptionValue);
  setAxiosDefaultHeaders(uid, bearerToken);
  axios
    .request({ method: 'POST', url: url, headers: { 'Content-Type': 'application/json;charset=UTF-8' }, data: rawData })
    .then(function (response) {
      // console.log(response.id);
      //setPushServerSubscriptionId(response.id);
    })
    .catch(err => {
      console.log(err);
      //setError(err);
    });
}

export async function setAnnotationSet(user:UserProps, annotationSet:AnnotationSetSchemaProps){
  if(!scoreitApiEndpoint){
    return [];
  }
  if(!user.firebaseUser) return [];
  const url = `${scoreitApiEndpoint}/annotation-sets`;

  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  let resultData:any[] = [];
  // try{
  const responseData = await axios.post(url, annotationSet, 
    { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  resultData = responseData.data;
  // }catch(error){
  // console.log('401 ERROR on /annotation-sets');
           
  // }
  // return [];
}

export async function setAssessment(firebaseUser:FirebaseUserProps|undefined, asmtPayload:AssessmentSchemaProps){
  if(!scoreitApiEndpoint || !firebaseUser){
    return undefined;
  }
  const url = `${scoreitApiEndpoint}/assessments`;

  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  console.log('bearerToken:', bearerToken);
  try{
    const result = await axios.post(url, asmtPayload);
    return result.data;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return undefined;
}

export async function patchAssessment(firebaseUser:FirebaseUserProps|undefined, assessmentPayload:Partial<AssessmentSchemaProps>){
  if(!scoreitApiEndpoint || !firebaseUser) return undefined;
  const assessmentId = assessmentPayload.assessmentId;
  if(!assessmentId) return undefined;
  delete assessmentPayload.assessmentId;
  const url = `${scoreitApiEndpoint}/assessments/${assessmentId}`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.patch(url, assessmentPayload);
    return response.data;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return undefined;
}
// Temp holding function for now (June 23 dlp) this functionality should eventually 
// be put into a long process part of the db
export async function updateReportFileInfo(firebaseUser:FirebaseUserProps|undefined, 
  reportPayload:Partial<ReportSchemaProps>):Promise<boolean>{
  if(!firebaseUser) return false;///ug probably should give some sort of message/log
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  if(reportPayload.reportId){
    const url = `${scoreitApiEndpoint}/reports/${reportPayload.reportId}`;

    const {reportId, ...rest} = reportPayload;
    const result = await axios.patch(url, rest);
    // result should have no data but 200 level response
    return true;
  }
  return false;
}
// This should be used to set individual parts of a report, which may or may not be allowed
export async function setReportFileInfo(firebaseUser:FirebaseUserProps|undefined, reportPayload:ReportSchemaProps)
:Promise<ReportSchemaProps|undefined>
{
  if(!scoreitApiEndpoint || !firebaseUser){
    return undefined;
  }

  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  // console.log('calling reports/ with uid:', uid);
  // console.log('token:', bearerToken);
  // console.log('payload: ', reportPayload);
  // console.log('firebaseUser:', firebaseUser);

  try{
    //hack for debugging, updating of reports
    if(reportPayload.reportId){
      const url = `${scoreitApiEndpoint}/reports/${reportPayload.reportId}`;
      const result = await axios.patch(url, reportPayload);
      // result should have no data but 200 level response
      return reportPayload;
    }
    const url = `${scoreitApiEndpoint}/reports`;
    const result = await axios.post(url, reportPayload);
    if(result.data){
      return result.data;
    }
  }catch(error){
    console.log('API ERROR:',error);
  }
  return undefined;
}

export async function setVideo(firebaseUser:FirebaseUserProps|undefined, videoPayload:VideoSchemaProps){
  if(!scoreitApiEndpoint || !firebaseUser){
    return {};
  }
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  const url = `${scoreitApiEndpoint}/videos`;
  try{

    const response = await axios.post(url,videoPayload);
    // if (response.data.videoExisted) {
    //   console.log(`Video instance ${response.data.videoId} already existed in database.`);
    // } else {
    //   console.log(`Created video instance ${response.data.videoId}`);

    // }
    // if (response.data.observationExisted) {
    //   console.log(`Observation instance ${response.data.observationId} already existed in database.`);
    // } else if (response.data.observationId !== undefined) {
    //   console.log(`Created observation instance ${response.data.observationId}`);
    // } else {
    //   console.log('Observation instance could not be created');
    // }
    return response.data;
  } catch (err){
    console.log('Failed to create video instance');
    console.log(err);
  }
  return {};
  // try {
  //   let response = await axios.post(self.emmaApiUri + '/videos', {
  //oddly enough utcCreatedDate and localCreatedDate have the same value
  //     'timestamp': new Date(video.utcCreatedDate).toISOString(), 
  //     'duration': video.mediaDurationSecond,
  //     'url': url,
  //     'thumbnailurl': thumbUrl,
  //     'bucketName': bucket.name,
  //     'jsonStoragePath': jsonStoragePath,
  //     'thumbnailStoragePath': thumbnailStoragePath,
  //     'videoStoragePath': videoStoragePath,
  //     'deviceId':  video.deviceId,
  //     'ownerId': video.ownerId
  //   });
  //   if (response.data.videoExisted) {
  //     logger.debug(`Video instance ${response.data.videoId} already existed in database.`);
  //   } else {
  //     logger.debug(`Created video instance ${response.data.videoId}`);
  //     count++;
  //   }
  //   if (response.data.observationExisted) {
  //     logger.debug(`Observation instance ${response.data.observationId} already existed in database.`);
  //   } else if (response.data.observationId !== undefined) {
  //     logger.debug(`Created observation instance ${response.data.observationId}`);
  //   } else {
  //     logger.warn('Observation instance could not be created');
  //   }

  // } catch (err) {
  //   logger.error('Failed to create video instance');
  //   logger.error(err);
  //   continue;
  // }
}

export async function setVideoPartial(firebaseUser:FirebaseUserProps|undefined, videoId:string, video:Partial<VideoSchemaProps>){
  if(!scoreitApiEndpoint || !firebaseUser){
    return {};
  }
  const {uid, bearerToken } = firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  const url = `${scoreitApiEndpoint}/videos/${videoId}`;
  try{

    const response = await axios.patch(url, video);
    return response.data;
  } catch (err){
    console.log('Failed to create video instance');
    console.log(err);
  }
  return {};
}

export async function sendVideoForProcessing(user:UserProps, videoId:string, deriveddataTypeName:string) {
  if(!scoreitApiEndpoint) return;
  //'/videos/process/{videoId}'\
  //using path.join doesnt work
  const endpoint = `${scoreitApiEndpoint}/videos/process/${videoId}`;
  if(!user.firebaseUser) return [];
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);
  //ugh
  return await axios.post(endpoint, {deriveddataType: deriveddataTypeName });
}

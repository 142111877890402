import React from 'react';
import {
  getDerivedDataVideo, 
  getActivitiesForRecordingDevices
} from '../../common/apiGetUtilities';
import {
  Tabs,
  Tab,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Divider,
  Paper
} from '@material-ui/core';

import {VideoDetailsTabPanel} from './components/VideoDetailsTabPanel';
import { ReadJsonFromUrl } from '../../common/entityUtilities';

export class VideoDetails extends React.Component {
    state = {
      open: false,
      fullWidth: true,
      maxWidth: 'sm',
      thumbnailUrl: {},
      timestamp:'',
      videoData: {},
      videoOverlayData: {},
      videoOverlayDataOP: {},
      videoOverlayDataActivity: {},
      motorRisk: {},
      motorRiskP: {},
      markers: [],
      tabIndexValue: 0,
      isPlaying: false,
      selected:0
    };

    handleDerivedData =  (data) => {
      if (data && data.length > 0){
        for (let i=0; i < data.length; i++){
          const dd = data[i];
          const props = dd.additionalProps;
          if (props.name === 'keypoints_video'){
            this.setState({videoOverlayData: dd.url});
            console.log('DD url for keypoints_video:', dd.url);
          }
          else if (props.name === 'keypoints_video_openpose') {
            this.setState({videoOverlayDataOP: dd.url});
            console.log('DD url for keypoints_video_openpose:', dd.url);
          }
          else if (props.name === 'activities_video'){
            this.setState({videoOverlayDataActivity: dd.url});
            console.log('DD url for activities_video:', dd.url);
          }
          else if (props.name === 'motor_risk'){
            // dd.url has two components: first one is video, second is json containing bayesian surprise data      
            const urlArray = dd.url ? dd.url.split('|') : '';
            console.log('urlArray from derived data:', urlArray);
            this.setState({videoOverlayDataOP: urlArray[0]});
            if (urlArray && urlArray.length > 1){
              this.getDataFromUrl(urlArray[1]);
              
            }
          }
        }
      }
    };

    getDataFromUrl = async (url) => {
      
      console.log('json url:', url);
      const res = await fetch(url)
        .then(res => res.json())
        .catch(err => {throw err;});
      const jsonResult = JSON.parse(res);
      console.log('p value:', jsonResult.p);
      console.log('z value:', jsonResult.z);
      console.log('infant:', jsonResult['infant']);

      this.setState({motorRisk: jsonResult});
    }

    setVideoData = async (data, userInfo) => {
      console.log('In VideoDetails->');
      this.setState({videoData:data});
      this.setState({thumbnailUrl: data.thumbnailurl}, function(){
        console.log('Thumbnail set:',this.state.thumbnailUrl);
      });

      this.setState({timestamp: new Date(data.timestamp).toLocaleString()});

      //get all derived data for this video from API
      const idToUse = data.videoId?data.videoId:data._id;
      const derivedData = await getDerivedDataVideo(userInfo, idToUse);
      console.log('Derived Data from API:', derivedData);
      this.handleDerivedData(derivedData);
      // let apiendpoint = getScoreitApiEndpoint() + '/videos/deriveddata/' + data.videoId;
      // response = await axios.get(apiendpoint);
      // console.log('Derived data response:', response);
      // let deriveddata = response.data;
      // this.handleDerivedData(deriveddata);
      
      const markers = getActivitiesForRecordingDevices(idToUse);
      if (Array.isArray(markers)) {
        this.setState(markers);
      }
      //get marker data from API
      // apiendpoint = getScoreitApiEndpoint() + '/activities?videoId=' + data.videoId;
      // let response = await axios.get(apiendpoint);
      // let markers = response.data;
      // if (Array.isArray(markers)) {
      //   this.setState({markers:response.data});
      // }
    }

    handleClickOpen = () => {
      this.setState({ open: true }, function(){
        console.log('dialog open state',this.state.open);
      });
    };
  
    handleClose = () => {
      this.setState({ open: false });
    };
  
    handleMaxWidthChange = event => {
      this.setState({ maxWidth: event.target.value });
    };
  
    handleFullWidthChange = event => {
      this.setState({ fullWidth: event.target.checked });
    };

    handleTabChange = (event, newValue) => {
      this.setState({selected:newValue}, function(){
        console.log('New tab value:',this.state.selected);
      });
    };

    a11yProps(index) {
      return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
      };
    }
      

    render() {
      return (  
        <Paper mt={4}>
          <React.Fragment>               
            <Dialog
              aria-labelledby="max-width-dialog-title"
              fullWidth={this.state.fullWidth}
              maxWidth={this.state.maxWidth}
              onClose={this.handleClose}
              open={this.state.open}
            >
              {this.props.user.authLevel > 0 &&
                <Paper>
                  <Tabs 
                    aria-label="Video"
                    centered
                    indicatorColor="primary" 
                    onChange={this.handleTabChange} 
                    value={this.state.selected}
                  >
                    <Tab
                      label="Raw video"
                      {...this.a11yProps(0)}
                    />
                    <Tab
                      label="Pose (infant)"
                      {...this.a11yProps(1)}
                    />
                    <Tab
                      label="Pose (all)"
                      {...this.a11yProps(3)}
                    />
                    <Tab
                      label="Activities"
                      {...this.a11yProps(2)}
                    />
                  
                  </Tabs>
                </Paper>              
              }    
              {this.props.user.authLevel == 0 &&
                <Paper>
                  <Tabs 
                    aria-label="Video"
                    centered
                    indicatorColor="primary" 
                    onChange={this.handleTabChange} 
                    value={this.state.selected}
                  >
                    <Tab
                      label="Home Video"
                      {...this.a11yProps(0)}
                    />
                  </Tabs>
                </Paper>
              } 
              <Divider my={6} />
              <VideoDetailsTabPanel
                index={0}
                value={this.state.selected}
                videoData={this.state.videoData}
                timestamp={this.state.timestamp}
              />
              <VideoDetailsTabPanel
                index={1}
                value={this.state.selected} 
                videoData={this.state.videoData}
                videoOverlayData={this.state.videoOverlayData}
              /> 
              <VideoDetailsTabPanel
                index={2}
                value={this.state.selected} 
                videoData={this.state.videoData}
                videoOverlayDataOP={this.state.videoOverlayDataOP}
                motorRisk = {this.state.motorRisk}
              />
              <VideoDetailsTabPanel
                index={3}
                markers={this.state.markers} 
                value={this.state.selected}
                videoData={this.state.videoData}
                videoOverlayDataActivity={this.state.videoOverlayDataActivity}
              />
              <DialogActions>
                <Button
                  color="primary"
                  onClick={this.handleClose}
                >
                      Close
                </Button>
              </DialogActions>
              <Card>
                <CardContent />
              </Card>
            </Dialog>
          </React.Fragment>
        </Paper>
      );
    }
}
  
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Drawer, 
  SwipeableDrawer,
  IconButton,
  Grid,
  List,
  Typography
} from '@material-ui/core';
import {
  ViewQuilt,
  Home,
  MenuBook, Tune,
  ArrowBack as ArrowBackIcon,
  FormatListBulleted,
  VideoLibrary,
  Assessment
} from '@material-ui/icons';
import classNames from 'classnames';
// styles
import useStyles from './styles';
import { useTheme } from '@material-ui/styles';

import SidebarLink from './components/SidebarLink/SidebarLink';
import * as RouteNames from '../../common/routeNames';
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { useUserState } from '../../context/UserContext';
// this layout is dependent on what use is logged in please fix this
// soon.

function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();
  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);
  

  const [emmaUser] = useState(useUserState());

  useEffect( ()=>{

  },[]);

  const sidebarId1_Admin = {
    id: 1, 
    label: 'Settings & Tasks', 
    link: RouteNames.SETTINGS, //Needed for highlighting
    icon: <Tune />,
    children: [
        { label: 'Emma User Settings', link: RouteNames.EMMA_USER_SETINGS },
        {label: 'Manage Users', link: RouteNames.ADMIN_TASKS },
        { label: 'App Settings', link: RouteNames.APP_SETTINGS },
        { label: 'Tasks Video', link: RouteNames.VIDEO_TASKS },
        { label: 'Derived Data Status', link: RouteNames.DERIVED_DATA_STATUS },
        {label:'Messages', link: RouteNames.MESSAGES}
      
    ],
  };

  const sidebarId1_Parent = {
    id: 1, 
    label: 'Settings & Tasks', 
    link: RouteNames.SETTINGS, //Needed for highlighting
    icon: <Tune />,
    children: [
        { label: 'Emma User Settings', link: RouteNames.EMMA_USER_SETINGS },
    ],
  };

  const sideBarStucture = [
    { id: 0, label: 'Home', link: RouteNames.DASHBOARD, icon: <Home /> },
    emmaUser?.authLevel == 0 ? sidebarId1_Parent: sidebarId1_Admin,
    {
      id: 2,
      label: 'Videos',
      link: RouteNames.VIDEOS,
      icon: <VideoLibrary />,
      children: [
        { label: 'Home Videos', link: RouteNames.EMMA_VIDEOS },
        { label: 'Play Activities', link: RouteNames.OT_VIDEOS }
      ],
    },

    { id: 3, label: 'Assessments', link: RouteNames.ASSESSMENTS, icon: <Assessment /> },
    { id: 4, label: 'About', link: RouteNames.DOCUMENTATION, icon: <MenuBook /> },

    // { id: 5, label: 'Test', link: RouteNames.TEST_PAGE, icon: <FormatListBulleted /> },
  ];
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }


  function dismissSidebar(){
    toggleSidebar(layoutDispatch);
  }


  // ##################################################################
  useEffect(function() {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });
  
  return (
    <SwipeableDrawer 
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened && !isPermanent,
        [classes.drawerOpenZHack]: isSidebarOpened && isPermanent,
        [classes.drawerClose]: !isSidebarOpened && !isPermanent,
        [classes.drawerCloseZHack]: !isSidebarOpened && isPermanent,
        // [classes.drawerOpen]: isSidebarOpened,
        // [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened && !isPermanent,
          [classes.drawerOpenZHack]: isSidebarOpened && isPermanent,
          [classes.drawerClose]: !isSidebarOpened && !isPermanent,
          [classes.drawerCloseZHack]: !isSidebarOpened && isPermanent,
          // [classes.drawerOpen]: isSidebarOpened,
          // [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      onClose={() => toggleSidebar(layoutDispatch)}
      open={isSidebarOpened}
      variant={isPermanent ? 'permanent' : 'temporary'}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
      <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
        <ArrowBackIcon
          classes={{
            root: classNames(classes.headerIcon, classes.headerIconCollapse),
          }}
        />
      </IconButton>

      </div>
      <List className={classes.sidebarList}>
        {sideBarStucture.map(link => (
          <SidebarLink
            dismissSidebar={dismissSidebar}
            isSidebarOpened={isSidebarOpened}
            key={link.id}
            location={location}
            {...link}
          />
        ))}
      </List>
    </SwipeableDrawer>
  );

}
export default withRouter(Sidebar);